/* App.css */
/*
Name: Light Gold
Hex: #B29700
RGB: (178, 151, 0)
CMYK: 0, 0.151, 1, 0.301
AMERICAN GOLD	
Name: American Gold
Hex: #D4AF37
RGB: (212, 175, 55)
CMYK: 0, 0.174, 0.740, 0.168
SUNRAY	
Name: Sunray
Hex: #E1C158
RGB: (225, 193, 88)
CMYK: 0, 0.142, 0.608, 0.117
ASPARAGUS	
Name: Asparagus
Hex: #7DAA6A
RGB: (125, 170, 106)
CMYK: 0.264, 0, 0.376, 0.333
PALM LEAF	
Name: Palm Leaf
Hex: #619A46
RGB: (97, 154, 70)
CMYK: 0.370, 0, 0.545, 0.396
SAP GREEN	
Name: Sap Green
Hex: #438029
RGB: (67, 128, 41)
CMYK: 0.476, 0, 0.679, 0.498

This document validates as CSS level 3 + SVG!
<p>
    <a href="https://jigsaw.w3.org/css-validator/check/referer">
        <img style="border:0;width:88px;height:31px"
            src="https://jigsaw.w3.org/css-validator/images/vcss"
            alt="Valid CSS!" />
    </a>
</p>

<p>
    <a href="https://jigsaw.w3.org/css-validator/check/referer">
        <img style="border:0;width:88px;height:31px"
            src="https://jigsaw.w3.org/css-validator/images/vcss-blue"
            alt="Valid CSS!" />
    </a>
</p>
*/

:root {
  --primary-color: 212, 175, 55;
  --secondary-color: 125, 170, 106;
  --text-shadow: 1px 1px 2px #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: 212, 175, 55;
    --secondary-color: 125, 170, 106;
    --text-shadow: 1px 1px 2px #000;
  }
}

/* LAYOUT */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

html {
  background-color: #666;
}

body {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  min-height: 100vh;
}

body,
html {
  margin: 0;
  overflow: scroll;
}

#root {
  overflow: hidden;
}

.container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

section {
  /*padding-top: 16px;*/
}

header,
footer {
  margin: 0 8px 0;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

header a svg {
  fill: rgba(255, 255, 255, 0.3);
  stroke: white;
  filter: drop-shadow(1px 1px 2px #000);
}

header a.active svg:hover,
header a svg:hover {
  fill: white;
}

footer {
  font-size: 0.8em;
  font-weight: 500;
  margin: 8px 0;
}

footer a {
  font-size: 1.2em;
  margin-right: 12px;
}

h1 a {
  background-color: linear-gradient(to right, #d4af37, rgb(255, 106, 0));
}

footer,
h1 a,
a {
  text-shadow: 1px 1px 2px #000;
  color: white;
  text-decoration: none;
}

footer,
.screen-details {
  margin: 0 8px 0;
}

/* NATIVE */
h1 {
  background: linear-gradient(to bottom right,#d4af37,#ff6a00);
  border: 2px solid #0000;
  /*border-image: linear-gradient(to bottom right,#7daa6a,#d4af37) 1;*/
  box-shadow: 1px 1px 2px #000;
  font-size: 1.8em;
  margin-top: 0;
  margin-bottom:8px;
  padding: 2px 16px 4px;
}

h1 a {
  text-decoration: none;
}

h2, nav a {
  font-size: 2em;
  font-weight: 800;
  line-height: 1.4em;
  margin-top: 0;
  background: transparent linear-gradient(to bottom right, #d4af37, #222);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-stroke: 1px rgb(255, 106, 0);
}

nav a {
  text-shadow: none;
  color: transparent !important;
  text-transform: uppercase !important;
  font-size: 1.5em;
}

/*h2 sub,
h3 sub {
  color: #7DAA6A;
  text-transform: capitalize;
}*/

h3 {
  color: #d4af37;
  text-shadow: 1px 1px 2px #000;
}

h3 {
  align-items: baseline;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
}

h4 {
  color: #d4af37;
  font-weight: 600;
  line-height: 1.2em;
  margin: 8px 0;
}

h4,
p,
a,
button {
  font-size: 1em;
  text-shadow: 1px 1px 2px #000;
}

p {
  font-weight: 400;
  line-height: 1.4em;
  margin: 12px 0;
  color: #e6e6e6;
}

a[href^=tel] {
  color: #000;
  text-decoration: none;
}

fieldset {
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
}

hr {
  background: linear-gradient(to right, #d4af37, rgb(255, 106, 0));
  height: 1px;
  border: 0;
  margin: 16px 0;
}

main a,
.page-controls a,
.form-signup a {
  color: rgb(255, 106, 0);
  display: inline-block;
  font-weight: 500;
  text-decoration: none;

}

.form-signup p:first-child a {
  font-size: 1em;
}

.form-signup input {
  margin-top: 12px !important;
}

.form-signup button {
  margin-top: 16px;
}

.page-controls a {
  color: #d4af37;
  font-weight: 700;
}

main a:hover {
  color: #fff;
}

em {
  display: block;
  font-size: 0.8em;
  margin: 8px 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  line-height: 1.5em;
  position: relative;
}

li span {
  margin: 0 1px;
  display: inline-block;
}

em, li {
  text-shadow: 1px 1px 2px #000;
}

legend {
  margin: 0;
  padding: 0;
}

legend h3 {
  margin: 0 0 8px 0;
  padding: 0;
}

/* BUTTON */
button {
  background-color: transparent;
  border: none;
  box-shadow: 1px 1px 2px #000;
  color: white;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 12px;
  padding: 8px 16px;
}

.button-cta-logout, 
.btn-dish-builder {
  margin-bottom: 0
}

.accordion-title div:last-child button {
  margin-top: 0;
  margin-left: 16px;
  padding-left: 16px;
}

button:disabled,
.input:disabled {
  background-color: transparent;
  color: grey;
}

button:disabled:hover {
  background-color: transparent;
  cursor: default;
}

button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}

/* FORM */
.form input,
.form select,
.form textarea {
  border: none;
  font-size: 1em;
  padding: 8px 16px;
  width: 100%;
  border: 1px solid rgb(255, 106, 0);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form textarea {
  min-height: 80px;
}

.form select {
  color: grey;
  text-transform: capitalize;
  height: 32px;
  padding-left: 10px;
}

.form select option {
  padding-left: 10px;
}

.form label {
  font-size: 1.3em;
  display: block;
}

/*.form span {
  font-size: 0.8em;
  font-style: italic;
}*/

/* NAV */
nav ul {
  padding: 0;
}

nav li {
  display: inline-block;
  margin-right: 8px;
}

nav li a {
  text-decoration: none;
}

nav li a:hover {
  text-decoration: underline;
}

/* CLASSES */

/* Accordion */
.accordion {
  margin: 1em auto 2em;
}

.accordion-item {
  margin-bottom: 12px;
}

.accordion-title {
  border: 1px solid;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
  box-shadow: 1px 1px 2px #000;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  font-weight: 600;
  justify-content: space-between;
  text-transform: capitalize;
  background-color: rgba(255, 255, 255, 0.1);
  color: #d4af37; 
}

.accordion-title:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
}

.accordion-title,
.accordion-content {
  padding: 12px;
}

.accordion-content {
  border: 1px solid;
  border-top: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
  box-shadow: 1px 1px 2px #000;
}

.accordion-content p {
  text-shadow: none;
}

@media screen and (max-width: 700px) {
  .accordion {
    width: 100%;
  }
}

.background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

/* BUTTON CTA */
.button-cta {
  background-color: transparent;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
  box-shadow: 1px 1px 2px #000;
  color: rgb(255, 106, 0); 
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  /*text-transform: capitalize;*/
}

.full-width {
  width: 100%;
}
.full-width .button-cta{
  width: 100%;
  text-align: left;
}

button.button-cta sup {
  /*text-transform: uppercase;*/
  color: white;
}

button.button-cta {
  /*text-transform: uppercase;*/
}

.button-cta-warning {
  background: rgb(255, 106, 0);
}

.button-cta-build {
  width: auto;
  display: inline-block;
}

.cta-warning {
  color: rgb(255, 106, 0);
  font-weight: 600;
}

.clear-both {
  clear: both;
  display: block;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

/* DYNAMIC INPUTS */
.dish-builder h3 {
  margin-bottom: 8px;
}

.dish-builder .row {
  background-color: transparent;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
  box-shadow: 1px 1px 2px #000;
  margin: 12px 0;
  padding: 12px;
}

.dish-builder .col:first-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
}

.dish-builder .col ul li {
  display: flex;
  margin-bottom: 2px;
}

.dish-builder input,
.dish-builder textarea,
.dish-builder select {
  border: none;
  border-radius: 8px;
  font-size: 1em;
  margin-left: 8px;
  margin: 0 8px 0 0;
  padding: 8px 16px;
  width: 100%;
}

.dish-builder input {
  margin-bottom: 8px;
}

.dish-builder button.button-cta {
  margin: 0;
  margin-top: 8px;
}

.dish-builder select {
  appearance: none;
  border: none;
  cursor: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.dish-builder ul li {
  display: inline-block;
  margin-right: 4px;
}

.dish-builder ul li a {
  text-shadow: none;
  width: 100%;
  margin-left: 0;
  text-align: center;
  font-style: italic;
}

/* FLAG */
.flag {
  align-items: start;
  background-size: contain;
  background-repeat: no-repeat;
  border: #7daa6a solid 2px;
  box-shadow: 1px 1px 2px #000;
  display: inline-block;
  height: 40px;
  margin: 0 8px 8px 0;
  width: 40px;
}

.flag:hover {
  border-color: white;
}

.form-signup input[type="button"] {
  margin-top: 30px;
}

.form-signup p:first-child a {
  font-size: 1.2em;
}

/* ICONS */
.icon {
  display: inline-block;
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.icon-login {
  align-self: center;
}

.icon-back,
.icon-refresh,
.icon-top {
  color: #7daa6a;
  font-weight: 500;
  margin-bottom: 12px;
  padding-left: 36px;
  position: relative;
  text-decoration: none;
  text-shadow: 1px 1px 2px #000;
}

.icon-top {
  font-size: 1em;
}

.icon-back::before,
.icon-refresh::before,
.icon-top::before {
  font-size: 2em;
  left: 0;
  position: absolute;
  text-shadow: 1px 1px 2px #000;
  top: -4px;
}

.icon-top::before {
  left: 10px;
}

.icon-back:hover,
.icon-refresh:hover,
.icon-top:hover {
  color: #fff;
}

.icon-back::before {
  content: "\21A9";
}

.icon-refresh::before {
  content: "\21BA";
}

.icon-top::before {
  content: "\21E1";
}

.icon-search {
  fill: #d4af37;
  stroke: #d4af37;
}

.icon-search svg:hover {
  stroke: white;
}

.icon-search,
header a.active svg {
  fill: #d4af37;
}

/* PAGE CONTROLS */
.page-controls {
  font-size: 1.1em;
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-controls {
  margin: 8px;
}

.page-controls button {
  border: 0;
  background: none;
}

.page-controls svg {
  color: #d4af37;
  cursor: pointer;
  width: 30px;
  height: 30px;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4));
}

.page-controls svg:hover {
  color: white;
}

/* PLACEHOLDER */
.placeholder {
  background-image: url(../static/loading.webp);
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: auto;
  width: 160px;
}

/* SCREEN */
.screen {
  position: relative;
  margin: 0 8px 16px;
  min-height: 0;
}

.screen,
.page-controls {
  background-color: #0000006d;
  padding: 8px;
  /*padding: 8px 16px;*/
  border-radius: 8px;
}

/* FORM SEARCH */
.form-search ul{
  display: flex;
  flex-wrap: wrap;
  gap: 8px 32px;

}
.form-search input[type="radio"] {
  display: none;
}

.form-search label:not(:first-child) {
  display: inline-block;
  font-size: 1em;
  margin: 12px 8px 0 0;
}

/* SLIDER */
.slider,
.screen-details {
  background-color: #0000006d;
}

.slider {
  /*margin-bottom : 16px;*/
  padding: 8px;
  position: relative;
}

main .slider:first-child {
  padding-bottom: 0;
}

h2 {
  padding:0;
  margin-bottom: 16px;
}

.slider h2 {
  margin: 0 32px 4px 0;
  display: inline-block;
}

.slider button {
  margin: 0 0 12px;
}

.slider h3 sub {
  line-height: 0.6em;
}

.slider ul.slider-list {
  display: flex;
  margin: 0;
  min-height: 160px;
  overflow: auto;
  overflow-y: auto;
  padding: 0;
}


.slider ul.slider-list li {
  border: transparent solid 2px;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
  box-shadow: 1px 1px 2px #000;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  max-height: 160px;
  max-width: 160px;
  min-height: 160px;
  min-width: 160px;
  overflow: hidden;
  padding: 0;
}

.slider ul.slider-list li:hover {
  border-color: #fff;
  box-shadow: none;
}

.slider ul.slider-list li:hover h3,
.slider ul.slider-list li:hover h3 sub,
.slider ul.slider-list li:hover h4 {
  color: #fff;
}

.slider ul.slider-list li img {
  opacity: 0.5;
}

.slider ul.slider-list li:hover img {
  opacity: 0.4;
}

.slider ul.slider-list h4 {
  bottom: 12px;
  left: 12px;
  position: absolute;
}

.slider ul.slider-list li a {
  background-color: #333;
  height: 100%;
  position: relative;
}

.slider ul.slider-list li:hover a {
  background-color: #d4af37;
}

.slider ul.slider-list em {
  margin-top: 8px;
}

.slider ul.slider-list div {
  padding: 12px 12px 6px;
  position: absolute;
  top: 0;
}

.slider ul.slider-list-options {
  bottom: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
}

.slider ul.slider-list-options li {
  font-size: 0.8em;
  margin-bottom: 0.5rem;
  margin-right: 12px;
}

.slider ul.slider-list-options label {
  margin-left: 0.2rem;
  vertical-align: text-bottom;
}

.slider>em {
  color: white;
}

.slider-controls {
  bottom: 24px;
  position: absolute;
  right: 12px;
  text-align: right;
  display: none;
}

.slider-controls button {
  background-color: transparent;
  border: none;
  text-indent: -7000px;
  cursor: pointer;
}

.slider-controls button:first-child:before,
.slider-controls button:last-child:after {
  bottom: 12px;
  color: white;
  font-size: 5em;
  opacity: 0.8;
  position: absolute;
  right: 12px;
  text-align: right;
  text-shadow: 1px 1px 2px #000;
}

.slider-controls button:first-child:before {
  content: "\2190";
  margin-right: 50px;
}

.slider-controls button:last-child:after {
  content: "\2192";
}

.screen-details {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
}

.screen-details .image-frame {
  background-color: transparent;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, #7daa6a, rgb(255, 106, 0)) 1;
  box-shadow: 1px 1px 2px #000;
  margin-bottom: 10px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.screen-details .frame .button-cta {
  margin-top: 16px;
}

.screen-details .frame form,
.screen-details .frame .form {
  background-color: #d4af37;
  padding: 8px;
}

.screen-details h2 {
  display: inline-block;
  position: relative;
}

.screen-details h3 {
  margin-top: 0;
}

.screen-details img {
  min-width: 100%;
}

.screen-details ul {
  margin: 8px 0;
  padding-left: 0;
}

.screen-details li {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 4px;
}

.social-shares a {
  font-size: 0.8em;
}

.dish-builder i select {
  max-width: 100px;
  color: black;
  padding: 2px 8px;
  text-align: center;
}

.screen-details ul li span i sup a {
  text-transform: none;
}

.screen-details i sup {
  margin-left: 1px;
}

.dish-builder li i {
  font-size: 1em;
  display: inline-block;
  z-index: 10;
}

.dish-builder li i select {
  margin-bottom: 4px;
}

.dish-builder li i div {
  white-space: nowrap;
}


.form-create select {
  padding: 8px 16px;
  height: 35px;
}

select option {
  height: 20px;
}

.container_basket {
  margin-left: 0;
}

@media (min-width: 431px) {

  .container_basket .screen ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 32px;
  }

  .isMobileOnly {
    display: none;
    visibility: hidden
  }

  .dish-builder li i {
    display: flex;
    gap: 8px;
  }

  .dish-builder li i select {
    margin-bottom: 0;
  }

  .input,
  .button {
    max-width: 500px;
  }

  .dish-builder ul li a {
    width: unset;
  }

  .dish-builder .col ul li {
    display: inline-block;
    margin-right: 4px;
  }

  .dish-builder .row {
    align-items: flex-start;
    display: flex;
    gap: 12px;
  }

  .dish-builder input {
    margin-bottom: 0;
  }

  .dish-builder button.button-cta {
    margin: 0;
  }

  /* PLACEHOLDER */
  .placeholder {
    background-image: url(../static/loading.webp);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 200px;
    width: 200px;
  }

  .row {
    display: flex;
    gap: 40px
  }

  .screen-details .image-frame {
    max-width: 300px;
    float: left;
    margin: 0 16px 12px 0;
  }

  .screen-details img {
    max-height: 300px;
    min-height: 300px;
    max-width: 300px;
    min-width: 300px;
  }
  main .slider:nth-of-type(1) {
    padding-bottom: 0;
  }
}

@media (min-width: 961px) {
  header,
  footer {
    padding: 0 72px 0 36px;
    margin: 0;
  }
  header a svg {
    scale: 1.3;
  }
  footer {
    margin: 0;
    text-align: left;
  }
  fieldset{
    max-width: 70%;
  }
  .slider {
    padding: 8px 24px 8px;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 1.9em;
  }

  h3 {
    font-size: 1.4em;
  }

  .button-cta {
    width: auto;
    margin-right: 8px;
  }

  /* DYNAMIC INPUTS */
  .dish-builder .row {
    padding: 16px;
  }

  .icon-login {
    scale: 1.4;
  }

  .list-created-dish p {
    position: absolute;
  }

  .list-created-dish span {
    display: inline-block;
    text-align: right;
    width: 100%;
  }

  .page-controls {
    font-size: 1.3em;
    margin: 8px 32px;
    padding: 16px 32px;
  }

  .slider {
    padding: 32px;
    position: relative;
  }

  .slider ul.slider-list li {
    max-height: 200px;
    max-width: 200px;
    min-height: 200px;
    min-width: 200px;
  }
  .screen-details {
    padding: 32px;
    margin: 0 32px;
  }
  .screen-details .image-frame {
    float: left;
    margin-bottom: 24px;
    margin-right: 24px;
  }
  .screen-details .frame img,
  .slider-list-item img {
    animation: fadein 0.5s;
  }
  .screen-details ul li i {
    position: relative;
    bottom: -2px;
  }
  .screen {
    padding: 20px 36px;
    margin: 0 32px 16px; 
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* print styles */
@media print {
  body {
    background-color: none !important;
  }

  h1 a,
  footer,
  body * {
    border-radius: none !important;
    text-shadow: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  header,
  footer,
  .screen,
  .accordion * {
    padding: 0;
    margin-bottom: 4px;
  }

  .flag,
  .icon-login,
  .page-controls,
  .button-cta,
  .slider {
    display: none;
    visibility: hidden;
  }

  .screen-details {
    padding: 0;
  }

  img,
  .image-frame {
    width: 100px !important;
    height: 100px !important;
    max-width: none !important;
    left: unset;
  }

  a:not(h1 a),
  p {
    color: black !important;
  }
}
