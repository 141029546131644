/* multi-step-form */
#multi-step-form {
  margin: 0;
  padding: 0;
}

#multi-step-form nav {
  position: relative;
  height: 20px;
}

#multi-step-form nav .back {
  position: absolute;
  left: 0;
}

#multi-step-form nav .back a {
  margin-right: 8px;
}

#multi-step-form nav .next {
  position: absolute;
  right: 0;
}

#multi-step-form input, #multi-step-form textarea, #multi-step-form select {
  width:100%;
  border: none;
  padding: 8px 16px;
  font-size: 1em;
  margin-bottom: 8px;
  color: black;
  height: 38px;
  border: 1px solid #7daa6a;
}

#multi-step-form button select {
  height: unset;
  width: unset;
  border: none;
  padding: 8px 16px;
  font-size: 0.8em;
  margin-bottom: 0;
}

#multi-step-form button select  {
  height: unset;
}

#multi-step-form ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 32px;
}

#multi-step-form button {
  margin-bottom: 0;
}